import React from 'react';

// partnes
import ascential from '../../assets/images/partners/ascential.png';
import verizon from '../../assets/images/partners/verizon.png';
import lionslive from '../../assets/images/partners/lionslive.png';

// images
import lemonsRight from '../../assets/images/lemons-right.png';
import lemonsLeft from '../../assets/images/lemons-left.png';

const Partners = () => {

  return (
    <section className="section partners section--pink">

      <img className="partners__lemons-right" src={lemonsRight} alt="Lemons" />
      <img className="partners__lemons-left" src={lemonsLeft} alt="Lemons" />

      <div className="container container--sm">
        <div className="section__descriptions">
          <h3 className="section__small-title section__small-title--margin">PARTNERS</h3>
          <h2 className="dlig section__title">Cheers to our MediaLink Beach Online partners</h2>
        </div>
      </div>

      <div className="container container--sm partners-container">
        <div className="partners-blocks-wrapper">
          <ul className="partners-blocks">
            <li>
              <div className="partner-content">
                <img src={verizon} alt="Verizon" />
              </div>
            </li>
            <li>
              <div className="partner-content">
                <img src={ascential} alt="Ascential" />
              </div>
            </li>
            <li>
              <div className="partner-content partner-content--lions">
                <img src={lionslive} alt="LionsLive" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Partners;
