import React from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import CookiesBlock from '../../Components/CookiesBlock';

// images
import photo1 from '../../assets/images/about-photos/photo-1.jpg';
import photo2 from '../../assets/images/about-photos/photo-2.jpg';
import photo3 from '../../assets/images/about-photos/photo-3.jpg';
import photo4 from '../../assets/images/about-photos/photo-4.jpg';
import photo5 from '../../assets/images/about-photos/photo-5.jpg';
import photo6 from '../../assets/images/about-photos/photo-6.jpg';
import photo7 from '../../assets/images/about-photos/photo-7.jpg';
import photo8 from '../../assets/images/about-photos/photo-8.jpg';
import photo9 from '../../assets/images/about-photos/photo-9.jpg';
import photo10 from '../../assets/images/about-photos/photo-10.jpg';
import photo11 from '../../assets/images/about-photos/photo-11.jpg';
import photo12 from '../../assets/images/about-photos/photo-12.jpg';

const PreEvent = () => {
  return (
    <div className="about-layout">
      <Navbar />
      <CookiesBlock />
      <section className="section circle-gradient circle-gradient--reverse about-intro">
        <div className="container">
          <div className="section__descriptions about-intro__content">
            <h2>About US</h2>
            <h3>Nearly a decade of connecting industry leaders at Cannes Lions.</h3>
            <p className="description">MediaLink partners with some of the most forward-thinking companies in the media, 
              technology and entertainment industries to make meaningful 
              connections with marketers for exploration, insights and partnerships.</p>
            
            <div className="btns-wrapper">
              <a href="https://medialink.com" rel="noopener noreferrer" target="_blank" className="btn btn--underline btn--arrow">
                More on MediaLink
              </a>
              
              <a href="https://lionslive.canneslions.com" rel="noopener noreferrer" target="_blank" className="btn btn--underline btn--arrow">
                More on Lions Live
              </a>
            </div>
          </div>

          <div className="about-intro__video">
            <iframe title="AboutVideo" src="https://player.vimeo.com/video/425637677" 
            allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
      </section>

      <section className="about-photos circle-gradient">
        <div className="container">

          <div className="about-photos-container">
            <div className="img-wrapper">
              <img src={photo1} alt="Picture1" />
            </div>
            <div className="img-wrapper">
              <img src={photo2} alt="Picture2" />
            </div>
            <div className="img-wrapper">
              <img src={photo3} alt="Picture3" />
            </div>
            <div className="img-wrapper">
              <img src={photo4} alt="Picture4" />
            </div>
            <div className="img-wrapper">
              <img src={photo5} alt="Picture5" />
            </div>
            <div className="img-wrapper">
              <img src={photo6} alt="Picture6" />
            </div>
            <div className="img-wrapper">
              <img src={photo7} alt="Picture7" />
            </div>
            <div className="img-wrapper">
              <img src={photo8} alt="Picture8" />
            </div>
            <div className="img-wrapper">
              <img src={photo9} alt="Picture9" />
            </div>
            <div className="img-wrapper">
              <img src={photo10} alt="Picture10" />
            </div>
            <div className="img-wrapper">
              <img src={photo11} alt="Picture11" />
            </div>
            <div className="img-wrapper">
              <img src={photo12} alt="Picture12" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PreEvent;