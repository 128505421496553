import React, { useState } from 'react';

// images
import iconClose from '../../assets/icons/icon-close.svg';
import iconCloseWhite from '../../assets/icons/close-white.svg';

const CookiesBlock = (props) => {

  // cookies state 
  let cookiesState = false;
  if(localStorage.getItem('cookiesAcepted') !== null) {
    cookiesState = localStorage.getItem('cookiesAcepted');
  }

  let closeIconUrl = iconClose;
  if(props.hasOwnProperty('className') && props.className === 'dark') {
    closeIconUrl = iconCloseWhite;
  }

  // state data
  const [cookiesAcepted] = useState(cookiesState);
  const [showPopup, setShowPopup] = useState(!cookiesAcepted);

  // accept cookies event
  const acceptCookies = () => { 
    localStorage.setItem('cookiesAcepted', true);
    setShowPopup(false);
  }

  return (
    <section className={`site-cookies ${showPopup ? 'site-cookies--active' : ''} ${props.className ? props.className : ''}`}>
      <button type="button" onClick={() => acceptCookies()} className="btn-close">
        <img src={closeIconUrl} alt="Close" className="Close" />
      </button>
      <p>By using this site you consent TO THE MEDIALINK BEACH ONLINE <a href="/terms" target="_blank" rel="noopener noreferrer">Terms</a> and <a rel="noopener noreferrer" href="https://medialink.com/cookie-policy/" target="_blank">Cookies</a> Policies</p>
      <button onClick={() => acceptCookies()} className="btn btn--primary">Accept</button>
    </section>
  );
};

export default CookiesBlock;