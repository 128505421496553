import React, { useState } from 'react';
import logo from '../../assets/icons/logo.png';

const Navbar = () => {

  // menu open
  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <header className="site-header">
      <div className="container">
        <h1 className="header__logo">
          <a href='/'><img src={logo} alt="Logo"/></a>
        </h1>
        
        <button type="button" onClick={() => handleToggleMenu()} className="btn-hamburger"><span></span></button>
        
        <div className={`site-header__nav-wrapper ${menuOpen ? 'active' : ''}`}>
          <div className="nav-mobile-top">
            <a href='/'><img src={logo} alt="Logo"/></a>
            <button type="button" onClick={() => handleToggleMenu()} className="btn-hamburger is-active"><span></span></button>
          </div>

          <nav className={`site-header__nav`} >
            <a href="/about">About</a>
            <a href="https://lionslive.canneslions.com" target="_blank" rel="noopener noreferrer">Lions Live</a>
            <a href="https://medialink.com" target="_blank" rel="noopener noreferrer">MediaLink</a>
          </nav>

          <nav className="small-nav-mobile">
            <a href="https://medialink.com/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
            <a href="https://medialink.com/cookie-policy/" rel="noopener noreferrer">Cookies Policy</a>
            <a href="/terms" target="_blank" rel="noopener noreferrer" >Terms</a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
