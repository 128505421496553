import React from 'react';
import palms from '../../../assets/images/palms-right.png';
import Speakers from '../../../Components/EventsPage/Speakers';
import Moment from 'react-moment';

const PreEvent = (props) => {

  return (
    <div className="pre-event">
      <section className="event-intro">
        <img className="event-intro__palms" src={palms} alt="Palms" />
        <div className="container">
          <div className="event-intro__content">
            <div className="event-intro__descriptions">
              <div className="event-intro__top-data">
                <time className="title-pink">
                  <Moment format="MMMM Do, h:mm A">
                      {props.startDate}
                  </Moment>
                </time>
                <div className="event-intro__top_right">The Daily</div>
              </div>
              
              {props.formattedName && 
              <h2 dangerouslySetInnerHTML={{ __html: props.formattedName.replace(/\|\|/g, '<br/>')+' <span class="letter-pink">.</span>' }}>
              </h2>
              }
              
              {!props.formattedName && 
              <h2>
                { props.name }
                <span className="letter-pink">.</span>
              </h2>
              }
              
              {props.hasOwnProperty('shortDescription') &&
              <div className="event-intro__content__description"> 
                <p>{props.shortDescription}</p>
              </div>
              }
            </div>
           
            <div className="members-block">
              
              <div className="block-green-border members-block__description">

                {props.hasOwnProperty('longDescription') &&
                <p>{props.longDescription}
                  {props.hasOwnProperty('lastParagraph') &&
                    <strong>{props.lastParagraph}</strong>
                  }
                </p>
                }
               
              </div>

              {(!props.hasOwnProperty('exhibitors') || props.exhibitors.length === 0) && 
                <div className="no-speaker-top">
                  SPEAKERS TO BE ANNOUNCED
                </div>
              }
            </div>
            
            {props.hasOwnProperty('exhibitors') && props.exhibitors.length > 0 && 
                <Speakers type={'exhibitors'} speakerModerator={props.exhibitorModerator} speakersIds={props.exhibitors} />
              }
          </div>
        </div>
      </section>
      <section className="followed">
        <div className="container">
          <div className="followed__intro">
            <time className="title-pink">Followed by</time>
            <h2>A panel featuring leading brands, agencies, media companies and technologists.</h2>
          </div>
          
          {(!props.hasOwnProperty('guests') || props.guests.length === 0) && 
            <div className="no-speakers-block centered">
              <p>SPEAKERS TO BE ANNOUNCED</p>
            </div>
          }

          {(props.hasOwnProperty('guests') && props.guests.length > 0) && 
            <Speakers type={'guests'} speakerModerator={props.hasOwnProperty('guestModerator') && props.guestModerator.length > 0 ?
              props.guestModerator : 
              undefined} 
              speakersIds={props.guests} />
          }
        </div>
      </section>
    </div>
  );
};

export default PreEvent;