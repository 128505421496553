import React from 'react';

const Calendar = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6665 3H2.33321C1.59683 3 0.999878 3.59695 0.999878 4.33333V13.6667C0.999878 14.403 1.59683 15 2.33321 15H11.6665C12.4029 15 12.9999 14.403 12.9999 13.6667V4.33333C12.9999 3.59695 12.4029 3 11.6665 3Z" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.99988 1V4" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.99988 1V4" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M0.999878 6H12.9999" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
};

export default Calendar;