import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import CookiesBlock from '../../Components/CookiesBlock';

// events pages types
import PreEvent from './PreEvent';
import PostEvent from './PostEvent';
import EventLive from './EventLive';

// set Airtable API
import Airtable from 'airtable';
const base = new Airtable({ apiKey: 'keyTEB8Tnkq4sWpzk' }).base('appuE1QIULqgFl4tz');

const Event = ({ match }) => {

  // state data
  const [dataLoaded, setDataLoaded] = useState(false);
  const [eventStatus, setEventStatus] = useState('pre');
  const [eventData, setEventData] = useState({});

  // on page mount
  useEffect(() => {

    // fetch data 
    base('Events').find(match.params.eventId, function(err, record) {

      // status
      const status = record.fields.Status;
      let eventStatus = 'pre';

      if(status === 'Post') {
        eventStatus = 'post';
      }
      
      if(status === 'Live') {
        eventStatus = 'live';
      }
      console.log(record.fields)
      setEventData(record.fields);
      setEventStatus(eventStatus);
      setDataLoaded(true);
    });

  }, [match.params.eventId]);

  function EventPage(props) {
    const status = eventStatus;

    if (status === 'pre') {    
      return <PreEvent {...eventData} />;  
    }  

    if(status === 'live') {
      return <EventLive {...eventData} />;
    }
    
    return <PostEvent {...eventData} />;
  }

  return (
    <div className="event-layout">
      <Navbar />
      <CookiesBlock />
      <div className="event-layout-content">
        {dataLoaded && 
          <EventPage status={eventStatus} />
        }
      </div>
      <Footer />
    </div>
  );
};

export default Event;