import React from 'react';

const Arrow = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5.08334H9.16667" stroke="white" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.08334 1L9.16668 5.08333L5.08334 9.16667" stroke="white" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
};

export default Arrow;