import React, { useState } from 'react';
import axios from 'axios';
import palms from '../../../assets/images/palms-right.png';
import Speakers from '../../../Components/EventsPage/Speakers';

const EventLive = (props) => {
  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');
  
  const submitForm = (e) => {
    try { 
      e.preventDefault();
      if (!name.length || !question.length){
        return false;
      }
      const options = {
        method: 'POST',
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-Type": "application/json"
        },
        data: {
          name: name,
          question: question
        },
        url: 'https://medialink-sender-email.herokuapp.com/',
      };
      axios(options);
      setQuestion('');
      setName('');
      
    } catch(e){
      setQuestion('');
      setName('');
    }
  }


  return (
    <div className="event-live">
      <section className="event-intro">
        <img className="event-intro__palms" src={palms} alt="Palms" />
        <div className="container">
          <div className="event-intro__content">
            <div className="event-intro__top-data">
              <time className="title-pink">
                Live Now
              </time>
              <div className="event-intro__top_right">The Daily</div>
            </div>

            {props.formattedName && 
            <h2 dangerouslySetInnerHTML={{ __html: props.formattedName.replace(/\|\|/g, '<br/>')+' <span class="letter-pink">.</span>' }}>
            </h2>
            }
            
            {!props.formattedName && 
            <h2>
              { props.name }
              <span className="letter-pink">.</span>
            </h2>
            }

            <div className="event-intro__content__description">
              <p>{props.shortDescription}</p>
            </div>
          </div>

          <div className="members-block">
            <div className="block-green-border members-block__description">
              <p>{props.longDescription}</p>
            </div>
          </div>

          <div className="event-intro__video">
            <iframe title="Live Video" src={props.LiveVideo} 
            allow="autoplay; fullscreen" allowFullScreen></iframe>
          </div>
        </div>
      </section>

      <section className="event-live__form">
        <div className="container">
          <header className="event-form__titles">
            <time className="title-pink">GOT QUESTIONS?</time>
            <h2>Submit a question to our panel</h2>
          </header>

          <div className="event-form__form">
            <form className="event-form">
              <div className="event-form__row">
                <input placeholder="Full Name" type="text" onChange={e => setName(e.target.value)} value={name} />
              </div>
              <div className="event-form__row">
                <textarea placeholder="YOUR QUESTION" onChange={e => setQuestion(e.target.value)} value={question} />
              </div>
              <div className="event-form__row">
                <button type="submit" onClick={(e) => submitForm(e)} className="btn btn--primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </section>
      
      <section className="circle-gradient circle-gradient--pink event-live__exhibitors">
        <div className="container">
          <header className="event-live__exhibitors-titles">
            <time className="title-pink">SCHEDULE</time>
            <h2>What’s on, when?</h2>
          </header>
          
          <section className="followed">
            <div className="followed__intro">
              <time className="title-pink exhibitors-time">11:00–11:30</time>
              <h2>A fireplace conversation with:</h2>
            </div>

            {props.hasOwnProperty('exhibitors') && props.exhibitors.length > 0 && 
              <Speakers type={'exhibitors'} speakerModerator={props.exhibitorModerator} speakersIds={props.exhibitors} />
            }

          </section>
        </div>
      </section>

      <section className="followed">
        <div className="container">
          <div className="followed__intro">
            <time className="title-pink">Followed by</time>
            <h2>{props.followedByText}</h2>
          </div>

          {(props.hasOwnProperty('guests') && props.guests.length > 0) && 
            <Speakers type={'guests'} speakerModerator={props.hasOwnProperty('guestModerator') && props.guestModerator.length > 0 ?
              props.guestModerator : 
              undefined} 
              speakersIds={props.guests} />
          }

        </div>
      </section>
      
    </div>
  );
};

export default EventLive;