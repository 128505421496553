import React from 'react';
import mlLogo from '../../assets/images/ml-logo-footer.png';

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <nav className="site-footer__nav">
          <a href="https://medialink.com/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
          <a href="https://medialink.com/terms-of-service/" rel="noopener noreferrer" target="_blank">Terms</a>
          <a href="https://medialink.com/cookie-policy/" rel="noopener noreferrer" target="_blank">Cookies</a>
        </nav>

        <a href="https://medialink.com/" className="logo-footer" rel="noopener noreferrer" target="_blank">
          <img src={mlLogo} alt="Medialink logo" height="39" />
        </a>
      </div>
    </footer>
  )
};

export default Footer;