import React, { useEffect, useState } from 'react';
import Footer from '../../Components/Footer';
import CookiesBlockTalk from '../../Components/CookiesBlockTalk';
import logoBlack from '../../assets/images/logo-black.png';

// set Airtable API
import Airtable from 'airtable';
const base = new Airtable({ apiKey: 'keyTEB8Tnkq4sWpzk' }).base('appuE1QIULqgFl4tz');

const Talk = () => {
  
  // state data
  const [videoTalk, setVideoTalk] = useState('');
  const [isLive, setIsLive] = useState(false);

  // on page mount
  useEffect(() => {

    // fetch data 
    base('Links').select({
      filterByFormula: `{Name} = "talk-video-url"`
    }).eachPage(function page(records, fetchNextPage) {

        const row = records[0].fields;
        setVideoTalk(row.Link);

        if(row.Status === 'Live') {
          setIsLive(true);
        }

        fetchNextPage();
    }, function done(err) {
        if (err) { console.error(err); return; }
    })
    
  }, []);

  return (
    <div className="talk-layout">
      <section className="section talk section--dark talk-intro">
        <div className="container">
          <img src={logoBlack} className="talk__logo" alt="logo black" />
          <div className="talk__titles">
            {isLive && 
              <span className="talk__time">Live Now</span>
            }
            <h3>A Special Conversation:</h3>
            <h2>Taking Actions <br></br>Today for a More<br></br>Equitable Tomorrow</h2>
          </div>
          <div className="talk__descriptions">
            <h4>Fireside chat:</h4>
            <div className="talk__descriptions__block">
              <ul>
                <li>
                  <h4>Steve Stoute </h4>
                  <p>Founder and CEO, Translation and UnitedMasters</p>
                </li>
                <li>
                  <h4>Rita Ferro </h4>
                  <p>President, Advertising Sales & Partnerships, The Walt Disney Company</p>
                </li>
                <li>
                  <h4>Michael Kassan </h4>
                  <p>Chairman & CEO, MediaLink</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="talk-video">
        <div className="container">
          <div className="talk__video">
            <iframe title="Talk Video" src={videoTalk} 
              allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
      </section>
      <CookiesBlockTalk className="dark" />
      <Footer />
    </div>
  );
};

export default Talk;