import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import EventCard from './../EventCard';
import CalendarIcon from '../../Components/Icons/Calendar';

// images
import PalmsEventsLeft from '../../assets/images/palms-events-left.png';
import PalmsEventsRight from '../../assets/images/palms-events-right.png';
import PalmsEventsTopLeft from '../../assets/images/palms-events-top-left.png';
import PalmsEventsTopRight from '../../assets/images/palms-events-top-right.png';

// set Airtable API
import Airtable from 'airtable';
const base = new Airtable({ apiKey: 'keyTEB8Tnkq4sWpzk' }).base('appuE1QIULqgFl4tz');

const Events = () => {

  // check if object is empty
  function isEmptyObject(obj){
    return JSON.stringify(obj) === '{}';
  }

  // events data
  const [events, setEvents] = useState([]);

  // on page mount add events items
  useEffect(() => {

    base('Events').select({
      // Selecting the first 3 records in Grid view:
      maxRecords: 10,
      view: "Grid view",
    }).eachPage(function page(records, fetchNextPage) {

      let newRecords = [];

      for (const record of records) {
        if(!isEmptyObject(record.fields)) {
          newRecords.push(record);
        }
      }

      setEvents(newRecords);
      fetchNextPage();
    
    }, function done(err) {
      if (err) { console.error(err); return; }
    });

  }, []);
  
  const params = {
    slidesPerView: 3,
    spaceBetween: 30,
    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 22,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 22
      },
      640: {
        spaceBetween: 32,
        slidesPerView: 1.3,
      },
      300: {
        spaceBetween: 32,
        slidesPerView: 1.3,
        centeredSlides: true,
      }
    }
  }

  return (
    <section className="section tues-thurs">
      
      <img className="events-section__palms-top-left" src={PalmsEventsTopLeft} alt="Palms" />
      <img className="events-section__palms-top-right" src={PalmsEventsTopRight} alt="Palms" />
      <img className="events-section__palms-left" src={PalmsEventsLeft} alt="Palms" />
      <img className="events-section__palms-right" src={PalmsEventsRight} alt="Palms" />

      <div className="container">
        <div className="section__intro">
          <div className="section__descriptions">
            
            <div className="events-titles-wrapper">
              <h2 className="section__title">The Daily</h2>
              <span>AT MEDIALINK BEACH</span>
            </div>

            <div className="tues-thurs__block-descriptions">

              <div className="tues-thurs__descriptions">
                <p>A series of provocative, inspiring and action-oriented conversations 
                  featuring leading brands, agencies, media companies and technologists.</p>

                <p><strong>Tuesday, Wednesday and Thursday</strong> at 11:00AM ET<strong> starting June 16.</strong></p>
              </div>

              <a href="/The_Daily_at_MediaLink_Beach.ics">
                <button type="button" className="btn btn--black btn--calendar btn--outline">
                  Add to calendar <CalendarIcon />
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="events events-block">
          <h3 className="section__small-title">All events</h3>
          <div className="content-carousel">
            {events.length > 0 &&
              <Swiper {...params}>

              {events.map((event, index) => (
                <EventCard key={event.id} id={event.id} {...event.fields} />
              ))}

            </Swiper>
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default Events;