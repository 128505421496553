import React from 'react';
import palms from '../../../assets/images/palms-right.png';
import Moment from 'react-moment';
import Speakers from '../../../Components/EventsPage/Speakers';

const PreEvent = (props) => {
  
  return (
    <div className="post-event">
      <section className="event-intro section-video">
        <img className="event-intro__palms" src={palms} alt="Palms" />
        <div className="container">
          <div className="event-intro__content">
            <div className="event-intro__descriptions">
              <div className="event-intro__top-data">
                <time className="title-pink">
                  <Moment format="MMMM DD YYYY">
                    {props.endDate}
                  </Moment>
                </time>
                <div className="event-intro__top_right">The Daily</div>
              </div>

              {props.formattedName && 
              <h2 dangerouslySetInnerHTML={{ __html: props.formattedName.replace(/\|\|/g, '<br/>')+' <span class="letter-pink">.</span>' }}>
              </h2>
              }
              
              {!props.formattedName && 
              <h2>
                { props.name }
                <span className="letter-pink">.</span>
              </h2>
              }
              
            </div>
            
            <div className="event-intro__video">
              <iframe title="AboutVideo" src={props.PostVideo1}
              allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>
           
          </div>
        </div>
      </section>

      <section className={`event-intro section-members section-post-video 
      ${props.hasOwnProperty('OnDemandPanelAvailable') && props.OnDemandPanelAvailable ? 'section-video' : 'section-on-demand'}`}>
        <div className="container">
          
          <div className="event-intro__content">
            <div className="event-intro__content__description">
              <p>{props.shortDescription}</p>
            </div>
          </div>

          <div className="members-block">
            <div className="block-green-border members-block__description">
              <p>{props.longDescription}</p>
            </div>
          </div>

          {props.hasOwnProperty('exhibitors') && props.exhibitors.length > 0 && 
            <Speakers type={'exhibitors'} speakerModerator={props.exhibitorModerator} speakersIds={props.exhibitors} />
          }
          {props.hasOwnProperty('OnDemandPanelAvailable') && props.OnDemandPanelAvailable === true && 
            <div className="event-intro__video">
              <iframe title="AboutVideo" src={props.PostVideo2}
              allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>
          }
          {(!(props.hasOwnProperty('OnDemandPanelAvailable') || props.OnDemandPanelAvailable === false)) &&
            <div className="no-speaker-block">
              <p>
              ON DEMAND PANEL CONVERSATION COMING SOON
              </p>
            </div>
          } 
          
        </div>
      </section>

      <section className="followed section-post-video">
        <div className="container">
          <div className="followed__intro">
            <time className="title-pink">Followed by</time>
            <h2>{props.followedByText}</h2>
          </div>
          
          {(props.hasOwnProperty('guests') && props.guests.length > 0) && 
            <Speakers type={'guests'} speakerModerator={props.hasOwnProperty('guestModerator') && props.guestModerator.length > 0 ?
              props.guestModerator : 
              undefined} 
              speakersIds={props.guests} />
          }
          
        </div>
      </section>
    </div>
  );
};

export default PreEvent;