import React from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import Home from './Pages/Home/';
import Event from './Pages/Event/';
import About from './Pages/About/';
import Terms from './Pages/Terms/';
import Talk from './Pages/Talk/';
import './assets/css/main.scss';

function App() {
  return (
    <Router>
        <Switch> 
          <Route
            exact path="/event/:eventId"
            component={Event} />
          <Route
            exact path="/about"
            component={About} />
          <Route
            exact path="/terms"
            component={Terms} />
          <Route
            exact path="/talk"
            component={Talk} />
          
          <Route
            exact path="/"
            component={Home} /> 
          <Route component={Home} />
        </Switch>
    </Router>
  );
}

export default App;
