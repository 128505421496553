import React from 'react';

// set Airtable API
import Airtable from 'airtable';
const base = new Airtable({ apiKey: 'keyTEB8Tnkq4sWpzk' }).base('appuE1QIULqgFl4tz');

class Speakers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      speakers: [],
      moderators: [],
    };  
  }

  componentDidMount() {
    
    // stringify records to filter
    const dataToGet = this.props.speakersIds.map(i => "RECORD_ID() = '"+i+"'");

    const self = this;

    base(this.props.type).select({
      // Selecting the first 3 records in Grid view:
      maxRecords: 10,
      view: "Grid view",
      filterByFormula: "OR("+dataToGet.join()+")",
    }).eachPage(function page(records, fetchNextPage) {

      const moderators = records.filter(record => self.props.speakerModerator.includes(record.id));
      const speakers = records.filter(record => !self.props.speakerModerator.includes(record.id));

      self.setState({
        speakers: speakers,
        moderators: moderators,
      });
      fetchNextPage();
    
    }, function done(err) {
      if (err) { console.error(err); return; }
    });
  
  }

  render() {
    return (

      <div className="members-cards">

      {this.state.speakers.length > 0 && this.state.speakers.map((speaker, index) => (
      
      <div key={speaker.id} className={`card`}>
        <div className="card-img-wrapper">
          {speaker.fields.photo && 
            <img src={speaker.fields.photo[0].url} className="card-img-top" alt="Member" />
          }
        </div>
        <div className="card-body">
          <h5 className="card-title" lang="de" dangerouslySetInnerHTML={{ __html:
            speaker.fields.name !== 'To be Announced' ?
            speaker.fields.name.replace(/\s/g, "</br>") :
            speaker.fields.name
          }}></h5>
          <div className="card-text">
            <p>{speaker.fields.jobTitle}</p>
            <span className="letter-pink">{speaker.fields.company}</span>
          </div>
        </div>
      </div>
      
      ))}
      

      {this.state.moderators.length > 0 && this.state.moderators.map((speaker, index) => (
      
      <div key={speaker.id} className={`card card--moderator`}>
        <div className="card-img-wrapper">
          {speaker.fields.photo && 
            <img src={speaker.fields.photo[0].url} className="card-img-top" alt="Member" />
          }
          
          <span>Moderated By</span>
        </div>
        <div className="card-body">
          <h5 className="card-title" lang="de" dangerouslySetInnerHTML={{ __html: speaker.fields.name.replace(/\s/g, "</br>")}}></h5>
          <div className="card-text">
            <p>{speaker.fields.jobTitle}</p>
            <span className="letter-pink">{speaker.fields.company}</span>
          </div>
        </div>
      </div>
      
      ))}

    </div> 
  )}
}

export default Speakers;