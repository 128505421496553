import React from 'react';
import Moment from 'react-moment';
//import moment from 'moment';
import ArrowIcon from '../../Components/Icons/Arrow';

// set Airtable API
import Airtable from 'airtable';
const base = new Airtable({ apiKey: 'keyTEB8Tnkq4sWpzk' }).base('appuE1QIULqgFl4tz');

class EventCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      speakers: [],
      guests: [],
      eventStatus: 'pre',
    };  
  }

  fetchData(type, ids) {

    const dataToGet = ids.map(i => "RECORD_ID() = '"+i+"'");
    const self = this;

    // get data
    base(type).select({
      // Selecting the first 3 records in Grid view:
      maxRecords: 10,
      view: "Grid view",
      filterByFormula: "OR("+dataToGet.join()+")",
    }).eachPage(function page(records, fetchNextPage) {

      if(type === 'exhibitors') {
        self.setState({
          speakers: records
        });
      }

      if(type === 'guests') {
        self.setState({
          guests: records.sort((a, b) => (a.fields.company > b.fields.company) ? 1 : -1)
        });
      }

      fetchNextPage();
    
    }, function done(err) {
      if (err) { console.error(err); return; }
    });
  }

  // component mount
  componentDidMount() {
    
    // event exhibitors
    if(this.props.hasOwnProperty('exhibitors')) {
      this.fetchData('exhibitors', this.props.exhibitors);
    }

    // event guests
    if(this.props.hasOwnProperty('guests')) {
      this.fetchData('guests', this.props.guests);
    }
    
    // set event status
    let eventStatus = 'pre';

    if(this.props.Status === 'Post') {
      eventStatus = 'item-carousel-passed';
    }

    if(this.props.Status === 'Live') {
      eventStatus = 'item-carousel-live';
    }

    this.setState({
      eventStatus: eventStatus
    });
  }

  componentDidUpdate() {
    const colorsItems = document.getElementsByClassName("item-carousel-footer");
    const speakersBlock = document.getElementsByClassName("speakers-wrapper");
    let maxHeight = 0;
    let maxHeightSpeakers = 0;
    
    if(colorsItems.length > 0 && this.state.guests.length > 0) {
      for (let index = 0; index < colorsItems.length; index++) {
        const item = colorsItems[index];

        if(maxHeight === 0) {
          maxHeight = item.offsetHeight;
        } else {
          if(item.offsetHeight > maxHeight) {
            maxHeight = item.offsetHeight;
          }
        }
      }
      
      for (let index = 0; index < speakersBlock.length; index++) {
        const itemSpeaker = speakersBlock[index];

        if(maxHeightSpeakers === 0) {
          maxHeightSpeakers = itemSpeaker.offsetHeight;
        } else {
          if(itemSpeaker.offsetHeight > maxHeight) {
            maxHeightSpeakers = itemSpeaker.offsetHeight;
          }
        }
      }
      
      setTimeout(function() {
        // set height
        for (let index = 0; index < colorsItems.length; index++) {
          const item = colorsItems[index];
          item.style.height = maxHeight+'px';
        }
        
        // set height
        for (let index = 0; index < speakersBlock.length; index++) {
          const itemSpeaker = speakersBlock[index];
          itemSpeaker.style.height = maxHeightSpeakers+'px';
        }
      }, 500)
    }
  }

  render() {
    return (
      
      <div className={`item-carousel swiper-slide ${this.state.eventStatus}`}> 
        <a href={`/event/${this.props.id}`}>
          <div className="item-carousel-header">

            {this.state.eventStatus === 'item-carousel-live' &&
              <span className="date">
                Live
              </span>
            }

            {this.state.eventStatus !== 'item-carousel-live' &&
              <span className="date">
                <Moment format="MMMM DD">
                  {this.props.startDate}
                </Moment>
              </span>
            }
            
            <h2 className="title-item">{this.props.name}</h2>

            <div className="speakers-wrapper">
              {this.state.speakers.length > 0 && this.state.speakers.map((speaker, index) => (
                <p className="name">{speaker.fields.name} <span className="pink"> {speaker.fields.company}</span></p>
              ))}

              {this.state.speakers.length === 0 &&
                <p className="name">Speakers to be announced</p>
              }
            </div>

          </div>
          <div className="item-carousel-footer">
            <h3 className="item-carousel-footer-title">{this.props.followedByShortText}</h3>
            {this.state.guests.length > 0 &&
              <p className="list-names">
                {this.state.guests.map(function(guest) {
                  return guest.fields.company;
                }).join(', ')
                }
              </p>
            }
            
            {this.state.eventStatus === 'item-carousel-passed' &&
              <button type="button" className="btn btn-demand btn--white btn--underline btn--arrow">
                Watch on Demand <ArrowIcon />
              </button>
            }

            {this.state.guests.length === 0 &&
              <p className="list-names">Panelists to be announced</p>
            }
          </div>
        </a>
      </div>
  )}
}

export default EventCard;