import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Events from '../../Components/Events';
import Partners from '../../Components/Partners';
import Footer from '../../Components/Footer';
import CookiesBlock from '../../Components/CookiesBlock';
import ArrowIcon from '../../Components/Icons/Arrow';

// images
import meetingImage from '../../assets/images/meeting.jpg';
import lionImage from '../../assets/images/lions-panel.png';
import lionsLiveImage from '../../assets/images/lions-live-section.png';

// video
import flagsVideo from '../../assets/videos/flags-intro.mp4';

// set Airtable API
import Airtable from 'airtable';
const base = new Airtable({ apiKey: 'keyTEB8Tnkq4sWpzk' }).base('appuE1QIULqgFl4tz');

const Home = () => {
  
  // last link data
  const [nextEvent, setNextEvent] = useState('');

  // set muted to video
  useEffect(() => {
    const videoInput = document.getElementById('video-background').querySelector('video');
    videoInput.setAttribute('muted', '');

    // get last event
    base('Events').select({
      // Selecting the first 3 records in Grid view:
      maxRecords: 1,
      view: "Grid view",
    }).eachPage(function page(records, fetchNextPage) {

      setNextEvent(records[0].id);
      fetchNextPage();
    
    }, function done(err) {
      if (err) { console.error(err); return; }
    });
  }, []);

  return (
    <div className="home">
    
      <Navbar />
      <CookiesBlock />
      <section className="home-intro homepage">
        <div id="video-background" className="home-intro__video">
          <video src={flagsVideo} muted playsInline loop autoPlay></video>
        </div>
        <div className="container">
          <div className="home-intro__content">
            <time className="title-pink">June 16 - 18 @11am ET, 2020</time>
            <h2 className="dlig">Welcome back to the Beach<span>.</span></h2>
            <p className="description">Virtual thought leadership sessions and networking meetings brought to you by <a href="https://medialink.com/" target="_blank" rel="noopener noreferrer">MediaLink</a></p>
            
            <a href={`/event/${nextEvent}`}>
              <button type="button" className="btn btn--white btn--underline btn--arrow">
                Next event <ArrowIcon />
              </button>
            </a>
          </div>
        </div>
        {/*
        <div className="home-intro__footer">

        </div>
        */}
      </section>
      
      <Events />

      <section className="section virtual-beach">
        <div className="container container--sm">
          <div className="section__descriptions">
            <h3 className="section__small-title section__small-title--margin">Online Beach</h3>
            <h2 className="dlig">Here for a 1:1 Meeting?</h2>
            <p className="description">If you've set up time with MediaLink for one-on-one meetings and are looking for where to go, please check your calendar for the invite. For questions contact coremeetings@medialink.com</p>
            <a href="mailto:coremeetings@medialink.com" target="_blank" rel="noopener noreferrer" className="btn btn--underline btn--arrow">Got Questions? <ArrowIcon /></a>
          </div>

          <div className="virtual-beach__images">
            <img src={meetingImage} alt="Meeting" />
          </div>
        </div>
      </section>

      <Partners />
      
      <section className="section cannes section--black">
        <div className="container container--sm">
          <div className="section__descriptions section__descriptions--alt">
            <h3 className="section__small-title section__small-title--margin">CANNES LIONS</h3>
            <h2 className="dlig section__title">MediaLink Beach Online is in celebration & partnership with Cannes Lions Live</h2>
            <p className="description">Coming 22–26 June to your home screen.</p>
            <a href="https://lionslive.canneslions.com" target="blank" className="btn btn--white btn--underline btn--arrow">JOIN THE CONVERSATION <ArrowIcon /></a>
          </div>
        </div>

        <img src={lionsLiveImage} className="cannes__lions-logo-img" alt="Lions" />
        <img src={lionImage} className="cannes__img" alt="Lions" />
      </section>
      <Footer />
    </div>
  );
};

export default Home;